<template>
  <div class="footer" ref="footer">
    <div class="footer_top">
      <span @click="quotation">免费获取建站方案/报价</span>
      <div class="top_box" @click="quotation">
        <!-- <img src="../../assets/imgs/you.png" class="y_l" alt /> -->
        <img src="../../assets/imgs/clickHere_0.png" class="y_l" alt />

      </div>
    </div>
    <div class="footer_box">
      <div class="footer_box_top" ref="footerBox">
        <div class="item_l">
          <div class="l_logo" @click="toHome">
            <img :src="obj.image" class="l_log" alt />
          </div>
          <div class="l_phone">{{ obj.phone }}</div>
          <div class="address">地址：{{ obj.address }}</div>
          <div class="info">{{ obj.summary }}</div>
        </div>
        <div class="item_c" v-if="articleList.length">
          <div class="item_c_item" v-for="(item, index) in articleList" :key="index">
            <div class="c_title">{{ item.title }}</div>
            <div class="c_box">
              <div @click="to(index, itm)" v-for="(itm, idx) in item.list" :key="idx">{{ itm.title }}</div>
            </div>
          </div>
        </div>
        <div class="item_r">
          <div class="r_img_box">
            <img :src="obj.annex" class="r_img_t" alt />
          </div>
          <div>关注公众号，获取更多方案</div>
        </div>
      </div>
      <div class="footer_box_btn">
        <div class="copyright">
          <div class="forter_text">
            <span>
              Copyright©2021-{{ time }} cqxiaoai.com 重庆晓艾网络科技有限公司
              版权所有
            </span>
            <span>
              <a class="atext" target="_blank" href="https://beian.miit.gov.cn/">
                <img style="margin: 0px 2px 0 0" src="../../assets/imgs/beian.png" />&nbsp; | 渝ICP备2022013462号-1
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { articlelist4, articlelinfo1 } from "@/api/axios/axios.api";
import storage from "@/utils/storage";

export default {
  data() {
    return {
      outerWidth: '',
      articleList: [],
      obj: "",
      time: new Date().getFullYear()
    };
  },
  methods: {
    async getData() {
      let res = await articlelist4();
      this.articleList = res.data.list;
      if (!storage.footerInfo) {
        let rec = await articlelinfo1();
        this.obj = rec.data;
        storage.footerInfo = rec.data
      } else {
        this.obj = storage.footerInfo
      }

      if (window.outerHeight === screen.availHeight && window.outerWidth === screen.availWidth) {
        // this.$refs.top.style.width = 100 + '%'
      } else {
        // this.$refs.top.style.width = 1200 + 'px'
        // this.$refs.footer.style.padding = '0 20px'
        this.$refs.footerBox.style.padding = '20px'
      }
    },
    // 报价
    quotation() {
      // window.location.href = "https://w102.ttkefu.com/k/linkurl/?t=3E4AAG8";
      window.open("https://w102.ttkefu.com/k/linkurl/?t=3E4AAG8")
    },
    // 跳转
    to(index, item) {
      // this.$router.push(item.url);
      window.scrollTo(0, 0)
      window.location.href = item.url
    },
    toHome() {
      this.$router.push({ name: "home" });
    },
    // 屏幕窗口
    setSize() {
      if (window.outerHeight === screen.availHeight && window.outerWidth === screen.availWidth) {
        // this.$refs.top.style.width = 100 + '%'
        this.$refs.footerBox.style.padding = '0 10%'
        this.outerWidth = window.outerWidth
      } else {
        // this.$refs.top.style.width = 1200 + 'px'
        this.$refs.footerBox.style.padding = '20px'
        this.outerWidth = window.outerWidth
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  },
  watch: {
    outerWidth(e) {
      console.log('outerWidth', e);
      // this.setSize()
    },
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width : 1400px) {
  .footer {
    color: #fff;
    // min-width: 1300px;

    .footer_top {
      height: 155px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1e2a47;
      font-size: 32px;
      box-sizing: border-box;

      .top_box {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: #f2a12c;
        // box-shadow: #e47e08;
        border-radius: 50%;
        margin-left: 14px;
        cursor: pointer;
        transition: all 0.5s;

        .y_l {
          // width: 16px;
          // height: 18px;
          width: 135px;
          position: relative;
          top: 18px;
          left: 10px;
          // height: 35px;
          transition: all 0.5s;
        }
      }

      .top_box:hover .y_l {
        transform: scale(1.2);
        transition: all 0.5s;
      }
      span{
        cursor: pointer;
      }
    }

    // .footer_top .top_box:hover {
    //   transition: all 0.5s;
    //   box-shadow: 0 0 20px rgba(153, 153, 153, 1);
    //   background-color: #e47e08;
    // }

    .footer_box {
      background: url(../../assets/imgs/juxing10.png) no-repeat;
      background-size: 100% 100%;
      font-size: 14px;

      .footer_box_top {
        // padding: 61px 10% 39px 10%;
        padding: 40px 10%;
        // height: 461px;
        display: flex;
        justify-content: space-between;
        // background-color: #ccc;
        box-sizing: border-box;
        border-bottom: 1px solid #434343;

        .item_l {
          width: 367px;
          font-size: 14px;

          .l_logo {
            margin-bottom: 39px;
            cursor: pointer;

            .l_log {
              width: 261px;
              height: 67px;
            }
          }

          .l_phone {
            font-size: 29px;
            margin-bottom: 28px;
          }

          .address {
            margin-bottom: 52px;
          }

          .info {
            line-height: 22px;
            min-width: 367px;
          }
        }

        .item_c {
          flex: 1;
          padding: 0 8%;
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          .item_c_item {
            min-width: 110px;

            .c_title {
              font-size: 18px;
              margin: 40px 0 36px 0;
            }

            .c_box div {
              padding: 4px 0;
              cursor: pointer;
            }

            .c_box div:hover {
              // color: #000;
              text-decoration: underline;
            }
          }
        }

        .item_r {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .r_img_box {
            margin-bottom: 40px;

            .r_img_t {
              max-width: 187px;
              max-height: 187px;
            }
          }
        }
      }

      .footer_box_btn {
        .copyright {
          height: 56px;
          width: 100%;
          color: #ffffff;
          text-align: center;
          line-height: 54px;

          img {
            height: 15px;
            width: 15px;
          }

          .forter_text {
            display: flex;
            align-items: center;
            justify-content: center;

            .atext {
              margin-left: 40px;
              border: 0;
              text-decoration: none;
              color: #ffffff;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width : 1399px) {
  .footer {
    color: #fff;
    min-width: 1300px;

    .footer_top {
      height: 155px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1e2a47;
      font-size: 32px;
      box-sizing: border-box;

      .top_box {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: #f2a12c;
        // box-shadow: #e47e08;
        border-radius: 50%;
        margin-left: 14px;
        cursor: pointer;
        transition: all 0.5s;

        .y_l {
          // width: 16px;
          // height: 18px;
          width: 135px;
          position: relative;
          top: 18px;
          left: 10px;
          // height: 35px;
          transition: all 0.5s;
        }
      }

      .top_box:hover .y_l {
        transform: scale(1.2);
        transition: all 0.5s;
      }
    }

    // .footer_top .top_box:hover {
    //   transition: all 0.5s;
    //   box-shadow: 0 0 20px rgba(153, 153, 153, 1);
    //   background-color: #e47e08;
    // }

    .footer_box {
      background: url(../../assets/imgs/juxing10.png) no-repeat;
      background-size: 100% 100%;
      font-size: 14px;

      .footer_box_top {
        // padding: 40px 10% 40px 10%;
        padding: 20px;
        // height: 461px;
        display: flex;
        justify-content: space-between;
        // background-color: #ccc;
        box-sizing: border-box;
        border-bottom: 1px solid #434343;

        .item_l {
          width: 367px;
          font-size: 14px;

          .l_logo {
            margin-bottom: 39px;
            cursor: pointer;

            .l_log {
              width: 261px;
              height: 67px;
            }
          }

          .l_phone {
            font-size: 29px;
            margin-bottom: 28px;
          }

          .address {
            margin-bottom: 52px;
          }

          .info {
            line-height: 22px;
            min-width: 367px;
          }
        }

        .item_c {
          flex: 1;
          padding: 0 8%;
          display: flex;
          justify-content: space-between;
          font-size: 14px;

          .item_c_item {
            min-width: 110px;

            .c_title {
              font-size: 18px;
              margin: 40px 0 36px 0;
            }

            .c_box div {
              padding: 4px 0;
              cursor: pointer;
            }

            .c_box div:hover {
              // color: #000;
              text-decoration: underline;
            }
          }
        }

        .item_r {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .r_img_box {
            margin-bottom: 40px;

            .r_img_t {
              max-width: 187px;
              max-height: 187px;
            }
          }
        }
      }

      .footer_box_btn {
        .copyright {
          height: 56px;
          width: 100%;
          color: #ffffff;
          text-align: center;
          line-height: 54px;

          img {
            height: 15px;
            width: 15px;
          }

          .forter_text {
            display: flex;
            align-items: center;
            justify-content: center;

            .atext {
              margin-left: 40px;
              border: 0;
              text-decoration: none;
              color: #ffffff;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
</style>
