<template>
  <div>
    <!-- 头部 -->
    <div class="heigth" ref="top">
      <div class="let_logo" @click="toHome">
        <img v-if="obj" src="../../assets/imgs/toubu.png" class="l_log" alt />
      </div>

      <div class="right">
        <div @click="tabNav(item.path)" :class="tab == index ? 'tabs' : 'tab'" v-for="(item, index) in tabList"
          :key="index">
          <span>{{ item.name }}</span>
          <div></div>
        </div>
        <div class="right_img">
          <img src="../../assets/imgs/dianhua.png" class="r_logo" alt />
          <span v-if="obj">{{ obj.phone }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { articlelinfo1 } from "@/api/axios/axios.api";
import storage from "@/utils/storage";

export default {
  props: {
    tab: Number
  },
  data() {
    return {
      outerWidth: '',
      tabList: [
        { name: "首页", path: "home" },
        { name: "网站建设", path: "construct" },
        { name: "小程序开发", path: "applet" },
        { name: "公众号开发", path: "official" },
        { name: "APP开发", path: "app" },
        { name: "成功案例", path: "sucsess" },
        { name: "新闻资讯", path: "news" },
        { name: "关于晓艾", path: "about" }
      ],
      obj: null
    };
  },
  methods: {
    async tabNav(path) {
      this.$router.push(path);
      if (path == 'home') {
        let rec = await articlelinfo1();
        this.obj = rec.data;
        storage.footerInfo = rec.data
      }
    },
    async toHome() {
      this.$router.push({ name: "home" });
      let rec = await articlelinfo1();
      this.obj = rec.data;
      storage.footerInfo = rec.data
    },
    // 获取数据
    async getData() {
      if (!storage.footerInfo) {
        let rec = await articlelinfo1();
        this.obj = rec.data;
        storage.footerInfo = rec.data
      } else {
        this.obj = storage.footerInfo
      }
    },
    // 屏幕窗口
    setSize() {
      if (window.outerHeight === screen.availHeight && window.outerWidth === screen.availWidth) {
        // this.$refs.top.style.width = 100 + '%'
        this.$refs.top.style.padding = '0 10%'
        this.outerWidth = window.outerWidth
      } else {
        // this.$refs.top.style.width = 1200 + 'px'
        this.$refs.top.style.padding = '0 20px'
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
      // this.setSize()
    });
  },
  watch: {
    outerWidth(e) {
      // console.log('outerWidth', e);
      // this.setSize()
    },
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width : 1400px) {
  .heigth {
    padding: 0 10%;
    height: 109px;
    min-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    .let_logo {
      cursor: pointer;

      .l_log {
        width: 172px;
        // height: 44px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #4f4f4f;
      font-size: 16px;
      width: 70%;

      .tabs {
        color: #000;
        font-size: 16px;
        cursor: pointer;
        font-weight: bold;
        margin-right: 10px;
        position: relative;
        height: 109px;
        line-height: 109px;

        div {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          width: 100%;
          background-color: #000;
        }
      }

      .tab {
        cursor: pointer;
        color: #000;
        margin-right: 10px;
      }

      .tab:hover {
        font-weight: bold;
        text-decoration: dashed;
      }

      .right_img {
        display: flex;
        align-items: center;
        margin-left: 5%;

        .r_logo {
          width: 21px;
          height: 21px;
          margin-right: 8px;
        }
      }
    }
  }
}

@media screen and (max-width : 1399px) {
  .heigth {
    padding: 0 20px;
    height: 109px;
    min-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    .let_logo {
      cursor: pointer;

      .l_log {
        width: 172px;
        // height: 44px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #4f4f4f;
      font-size: 16px;
      width: 70%;

      .tabs {
        color: #000;
        font-size: 16px;
        cursor: pointer;
        font-weight: bold;
        margin-right: 10px;
        position: relative;
        height: 109px;
        line-height: 109px;

        div {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          width: 100%;
          background-color: #000;
        }
      }

      .tab {
        cursor: pointer;
        color: #000;
        margin-right: 10px;
      }

      .tab:hover {
        font-weight: bold;
      }

      .right_img {
        display: flex;
        align-items: center;
        margin-left: 5%;

        .r_logo {
          width: 21px;
          height: 21px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
