import axios from 'axios'
import {
    Message
} from 'element-ui'
import Qs from 'qs' // Qs最主要的作用就是将post请求参数转义为序列化查询字符串（x-www-form-urlencoded）
import storage from '@/utils/storage'
// 创建axios实例
const instance = axios.create({
    // baseURL: 'https://www.cqxiaoai.com/api/v1/',
    baseURL: '/',
    // baseURL: '/api',
    timeout: 60000, // request timeout
    // withCredentials: true, //全局设置请求头默认携带cookie
    // retry: 3 /* 设置请求失败之后重新请求次数 */,
    // retryInterval: 10000 /* 设置请求失败之后再次发起请求的间隔 */
})
// respone 拦截器
// 如果请求返回状态码介于200~300之间，会执行拦截器的第一个回调方法，我们可以在这里面做一些resultCode（业务code）的判断和逻辑处理
// 不在200~300之间，会执行第二个回调方法，我们可以在这里实现通知，把网络异常和错误信息显示到页面上
// instance.interceptors.response.use(
//     response => {
//         console.log('请求', response);
//         const resultCode = response.data.status || null
//         const errorMsg = response.data.message || '--'
//         if (resultCode && resultCode !== 200) {
//             Message.error(errorMsg);
//         }
//         return response
//     },
//     error => {
//         Message.error(error);
//         return Promise.reject(error)
//     })
// export default instance
// 配置拦截器
instance.interceptors.request.use(config => {
    // if (config.method === 'post') {
    //     config.data = Qs.stringify(config.data)
    // } else {
    //     config.data = Qs.parse(config.data)
    // }
    // 接口鉴权
    /*
        某些接口需要token秘钥（令牌）才能正常访问，可以在请求拦截器中统一设置token即可
    */
    // let token = storage.token
    // config.headers['Authorization'] = token

      config.headers['Authorization'] = 'Bearer 2f68dbbf-519d-4f01-9636-e2421b68f379'
      config.headers['Accept'] = 'application/json'
    // let cookie = storage.cookie

    // config.headers['Cookei'] = cookie

    // config.headers['Content-Type'] = 'multipart/form-data'
    // config必须返回，否则报错
    return config
}, error => {
    return Promise.reject(error)
})
// 响应拦截器
instance.interceptors.response.use(config => {
    if (config.data !== undefined && config.data.message !== undefined && config.data.message !== null && config.data.message !== '') {
        // Message.success(config.data.message);
        Message({
            // duration: 0,
            offset: document.documentElement.clientHeight / 2 - 100,
            type: 'success',
            message: config.data.message,
            center: true
        });
    }
    return config
}, error => {
    // if (error.response.status == 401) {
    //     return
    //     // Message.error(error.response.data.message);
    // } else {
    //     Message.error(error.response.data.message);
    // }
    if (error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined && error.response.data.message !== null && error.response.data.message !== '') {
        Message.error(error.response.data.message);
    }
    // Message({
    //     message: error.response.data.message,
    //     center: true
    // });
    return Promise.reject(error)
})
export default instance
