// 引入axios实例
import axios from '../index'
/*
    1.为什么要return？
        - 因为axios请求结果，返回的是一个promise
        - 此时return，那么调用就可以是：checkLogin(xxx).then() 或者 let res = await checkLogin(xxx)
    2. 为什么export？
        - 将函数声明暴露出去，在组件中引用
*/

/**
 * banner广告
 *
 * @url ad/list
 * @method get
 *
 * @param { String } pid          1-首页轮播banner
 */
export function adlist(params) {
    return axios.get('api/v1/ad/list', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err.response;
    })
}
/**
 * 首页-我们的服务/底部导航/案例类型
 *
 * @url article/list4
 * @method get
 *
 */
export function articlelist4(params) {
    return axios.get('api/v1/article/list4', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err.response;
    })
}
/**
 * 案例详情
 *
 * @url project/1
 * @method get
 *
 */
export function project(params) {
    return axios.get('api/v1/project/' + params).then(res => {
        return res
    }).catch(err => {
        return err.response;
    })
}
/**
 * 首页-我们成功的案例
 *
 * @url projects/index-recommends
 * @method get
 *
 */
export function articleindexrecommends(params) {
    return axios.get('api/v1/projects/index-recommends', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err.response;
    })
}
/**
 * 首页-合作伙伴
 *
 * @url article/list9
 * @method get
 *
 */
export function articlelist9(params) {
    return axios.get('api/v1/article/list9', {
        params
    }).then(res => {
        return res
    })
}
/**
 * 新闻推荐列表
 *
 * @url article/list10/recommend
 * @method get
 *
 * @param { String } page         分页，默认1
 */
export function articlelist10recommend(params) {
    return axios.get('api/v1/article/list10/recommend', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 新闻资讯列表
 *
 * @url article/list10
 * @method get
 *
 * @param { String } page         分页，默认1
 */
export function articlelist10(params) {
    return axios.get('api/v1/article/list10', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 案例列表
 *
 * @url projects
 * @method get
 *
 * @param { String } page         分页，默认1
 * @param { String } type_id      分类id
 */
export function projects(type, page) {
    return axios.get('api/v1/projects?type_id=' + type + '&page=' + page).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 新闻详情
 *
 * @url article/info10/10024
 * @method get
 *
 */
export function articleinfo10(params) {
    return axios.get('api/v1/article/info10/' + params).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * APP-定制开发价值
 *
 * @url article/list14
 * @method get
 *
 */
export function articlelist14(params) {
    return axios.get('api/v1/article/list14', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * APP-行业定制解决方案
 *
 * @url article/list11
 * @method get
 *
 */
export function articlelist11(params) {
    return axios.get('api/v1/article/list11', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 案例推荐
 *
 * @url projects/recommends/1
 * @method get
 *
 */
export function projectsrecommends(params) {
    return axios.get('api/v1/projects/recommends/' + params).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 网站建设-服务内容
 *
 * @url article/list20
 * @method get
 *
 */
export function articlelist20(params) {
    return axios.get('api/v1/article/list20', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 网站建设-竞争优势
 *
 * @url article/list21
 * @method get
 *
 */
export function articlellist21(params) {
    return axios.get('api/v1/article/list21', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 关于我们/底部公司信息
 *
 * @url article/info1
 * @method get
 *
 */
export function articlelinfo1(params) {
    return axios.get('api/v1/article/info1', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 小程序开发-解决方案
 *
 * @url article/list15
 * @method get
 *
 */
export function articlellist15(params) {
    return axios.get('api/v1/article/list15', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 小程序开发-定制商城
 *
 * @url article/list16
 * @method get
 *
 */
export function articlellist16(params) {
    return axios.get('api/v1/article/list16', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 公众号-定制介绍
 *
 * @url article/list17
 * @method get
 *
 */
export function articlellist17(params) {
    return axios.get('api/v1/article/list17', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 公众号-定制功能说明
 *
 * @url article/list18
 * @method get
 *
 */
export function articlellist18(params) {
    return axios.get('api/v1/article/list18', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}
/**
 * 公众号-平台营销服务
 *
 * @url article/list19
 * @method get
 *
 */
export function articlellist19(params) {
    return axios.get('api/v1/article/list19', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}

/**
 * 系统定制-系统介绍
 *
 * @url article/list12
 * @method get
 *
 */
export function articlellist12(params) {
    return axios.get('api/v1/article/list12', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}

/**
 * 系统定制-服务优势
 *
 * @url article/list13
 * @method get
 *
 */
export function articlellist13(params) {
    return axios.get('api/v1/article/list13', {
        params
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}